import React from 'react';
import ErrorBoundary from '@dnb/error-boundary';
import { H1, P } from '@dnb/eufemia';
import Head from '@dnb/helmet';
import DataFetchingComponent from '../../components/DataFetchingComponent';

function PageIndex() {
  return (
    <>
      <Head>
        <title>Web Framework demos</title>
      </Head>

      <H1 bottom="1rem" top="3rem">
        useErrorHandler demos
      </H1>
      <P bottom="2rem">
        This page demonstrates how to catch other errors with the ErrorBoundary
        component.
      </P>

      {/** Wrapping the component in a local ErrorBoundary to catch the error ahead of GlobalErrorBoundary */}
      <ErrorBoundary>
        <DataFetchingComponent />
      </ErrorBoundary>
    </>
  );
}

export default PageIndex;
