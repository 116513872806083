import React, { useEffect } from 'react';
import { useErrorHandler } from '@dnb/error-boundary';
import { ProgressIndicator } from '@dnb/eufemia';
import { InfoCard } from '@dnb/eufemia';

export default function DataFetchingComponent() {
  const handleError = useErrorHandler();
  useEffect(() => {
    fetch('/data/not-existing')
      .then((res) => {
        if (!res.ok) throw new Error('Error in the DataFetchingComponent');
        return res;
      })
      .catch((e) => handleError(e));
  }, [handleError]);

  return (
    <InfoCard
      title="DataFetchingComponent"
      text="The component is loading"
      bottom="1rem"
      icon={ProgressIndicator}
    />
  );
}
